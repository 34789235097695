<template>
    <div class="">
        <b-row class="auth-inner m-0">
            <!-- Register-->
            <b-col class="d-flex align-items-center auth-bg px-2 py-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <div v-if="withSocialite" class="alert alert-primary">
                        <div class="alert-body">
                            <div class="d-flex align-items-center">
                                <b-avatar :src="query.avatar" size="lg" class="mr-1" />
                                <div>
                                    <p>{{ query.name }}</p>
                                    <p>{{ $t('messages.binding_socialite_register') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                        {{ $t('messages.starts_here') }}
                    </b-card-title>
                    <b-card-text class="mb-2"></b-card-text>

                    <!-- form -->
                    <validation-observer ref="registerForm">
                        <b-form class="auth-register-form mt-2" @submit.prevent>
                            <!-- register type -->
                            <b-form-group :label="$t('fields.register_type') + ' *'" label-for="register-type">
                                <validation-provider v-slot="{ errors }" name="Register Type" rules="required">
                                    <b-form-radio-group id="register-type" v-model="form.register_type">
                                        <b-form-radio value="individual" name="register-type">
                                            {{ $t('fields.individual') }}
                                        </b-form-radio>
                                        <b-form-radio value="company" name="register-type">
                                            {{ $t('fields.company') }}
                                        </b-form-radio>
                                    </b-form-radio-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <!-- first name、last name -->
                            <b-row v-if="form.register_type === 'individual'">
                                <b-col>
                                    <validation-input
                                        id="first-name"
                                        v-model="form.first_name"
                                        :label="$t('fields.first_name')"
                                        field="first_name"
                                        rules="required"
                                    />
                                </b-col>
                                <b-col>
                                    <validation-input
                                        id="last-name"
                                        v-model="form.last_name"
                                        :label="$t('fields.last_name')"
                                        field="last_name"
                                        rules="required"
                                    />
                                </b-col>
                            </b-row>
                            <!-- company name -->
                            <validation-input
                                v-if="form.register_type === 'company'"
                                id="register-company-name"
                                v-model="form.company_name"
                                :label="$t('fields.company_name')"
                                field="company_name"
                                :placeholder="$t('fields.apro')"
                                rules="required"
                            />
                            <!-- email -->
                            <validation-input
                                id="register-email"
                                v-model="form.email"
                                :label="$t('fields.email')"
                                field="email"
                                :placeholder="$t('messages.email_placeholder')"
                                rules="required|email"
                            />
                            <!-- password -->
                            <validation-input
                                id="register-password"
                                v-model="form.password"
                                field="password"
                                rules="required|password_strength"
                                :label="$t('fields.password')"
                                :type="password1FieldType"
                                :placeholder="$t('messages.password_placeholder')"
                            >
                                <template #append>
                                    <b-input-group-append is-text>
                                        <feather-icon
                                            :icon="password1ToggleIcon"
                                            class="cursor-pointer"
                                            @click="togglePassword1Visibility"
                                        />
                                    </b-input-group-append>
                                </template>
                            </validation-input>
                            <!-- confirm password -->
                            <validation-input
                                id="register-password-confirm"
                                v-model="form.password_confirmation"
                                field="confirm_password"
                                rules="required|confirmed:password|password_strength"
                                :label="$t('fields.confirm_password')"
                                :type="password2FieldType"
                                :placeholder="$t('messages.password_placeholder')"
                            >
                                <template #append>
                                    <b-input-group-append is-text>
                                        <feather-icon
                                            :icon="password2ToggleIcon"
                                            class="cursor-pointer"
                                            @click="togglePassword2Visibility"
                                        />
                                    </b-input-group-append>
                                </template>
                            </validation-input>
                            <!-- country -->
                            <validation-select
                                id="register-country"
                                v-model="form.country"
                                rules="required"
                                field="country"
                                :label="$t('fields.country')"
                                :options="countries"
                                label-key="name"
                                value-key="code"
                            />
                            <!-- checkbox -->
                            <b-form-group>
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="privacy_policy_terms"
                                    :rules="{ required: { allowFalse: false } }"
                                >
                                    <b-form-checkbox
                                        id="register-privacy-policy"
                                        v-model="form.accepted"
                                        name="register privacy policy"
                                    >
                                        {{ $t('messages.i_agree') }}
                                        <b-link>{{ $t('fields.privacy_policy_terms') }}</b-link>
                                    </b-form-checkbox>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <!-- captcha -->
                            <captcha ref="captcha" />
                            <!-- submit button -->
                            <b-button
                                v-ripple.500="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                class="px-5"
                                type="submit"
                                @click="validationForm"
                            >
                                {{ $t('fields.sign_up') }}
                            </b-button>
                        </b-form>
                    </validation-observer>
                </b-col>
            </b-col>
            <!-- /Register-->
        </b-row>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store/index'
import ValidationInput from '@/components/ValidationInput'
import ValidationSelect from '@/components/ValidationSelect'
import Captcha from '@/components/Captcha'
import auth from '@/api/auth'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import VerificationScope from '@/enums/VerificationScope'

export default {
    components: {
        ValidationSelect,
        ValidationInput,
        Captcha,
    },
    data() {
        return {
            form: {
                register_type: 'individual',
                email: '',
                first_name: '',
                last_name: '',
                password: '',
                password_confirmation: '',
                country: null,
                language: null,
                company_name: '',
                accepted: true,
            },
            query: {
                avatar: '',
                email: '',
                name: '',
                socialite: '',
                encrypt_credentials: '',
            },
            withSocialite: false,
            // Toggle Password Visibility
            password1FieldType: 'password',
            password2FieldType: 'password',
        }
    },
    computed: {
        ...mapState('global', ['socialites', 'countries']),
        password1ToggleIcon() {
            return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        password2ToggleIcon() {
            return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        imgUrl() {
            let image = require('@/assets/images/pages/register-v2.svg')

            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                image = require('@/assets/images/pages/register-v2-dark.svg')

                return image
            }

            return image
        },
        redirectLogin() {
            return {
                name: 'login',
                query: {
                    ...this.$route.query,
                },
            }
        },
    },
    watch: {
        'form.register_type'() {
            this.form.last_name = null
            this.form.first_name = null
            this.form.company_name = null
        },
        '$store.state.global.language': {
            handler(language) {
                this.form.language = language
            },
            immediate: true,
        },
    },
    mounted() {
        this.form.country = this.$store.state.global.country
        this.init()
    },
    methods: {
        async init() {
            const socialite = this.$route.query.socialite
            const encrypt_credential = this.$route.query.encrypt_credential
            const email = this.$route.query.email

            if (socialite && encrypt_credential) {
                this.withSocialite = true

                this.query = this.$route.query

                Object.assign(this.form, {
                    socialite,
                    encrypt_credential,
                    email,
                })
            }
        },
        togglePassword1Visibility() {
            this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
        },
        togglePassword2Visibility() {
            this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
        },
        validationForm() {
            this.$refs.registerForm.validate().then((success) => {
                if (success) {
                    this.$refs.captcha.execute(this.handleSubmit)
                }
            })
        },
        async handleSubmit(captcha_response) {
            try {
                const response = await auth.register({ ...this.form, captcha_response })
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: response.data.message,
                        icon: 'EditIcon',
                        variant: 'success',
                    },
                })
                this.$bvModal.hide('modal-register')
                this.$router.push({
                    path: '/login',
                    query: {
                        email: this.form.email,
                        scope: VerificationScope.VERIFY_EMAIL,
                    },
                })
                this.$bvModal.show('modal-verify-email')
            } catch (error) {
                //
            }
        },
        async redirectToSocialite(socialite) {
            console.log('redirectToSocialite', socialite)
        },
        redirectTo() {},
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
