<template>
    <b-form-group :label="labelName" :label-for="id">
        <validation-provider v-slot="{ errors }" :name="field" :rules="rules">
            <b-form-select
                :id="id"
                v-model="innerValue"
                :state="errors.length > 0 ? false : null"
                :options="options"
                :text-field="labelKey"
                :value-field="valueKey"
            />
            <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
    </b-form-group>
</template>
<script>
export default {
    name: 'ValidationSelect',
    props: {
        id: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        value: {
            type: null,
            default: null,
        },
        field: {
            type: String,
            required: true,
        },
        rules: {
            type: [String, Object],
            default: '',
        },
        options: {
            type: Array,
            required: true,
        },
        labelKey: {
            type: String,
            required: true,
        },
        valueKey: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            innerValue: null,
        }
    },
    computed: {
        labelName() {
            let text = this.label
            let rules = this.rules

            if (typeof rules !== 'string' && !(rules instanceof Array)) {
                rules = Object.keys(rules)
            }

            return rules.includes('required') ? text + ' *' : text
        },
    },
    watch: {
        value(value) {
            this.innerValue = value
        },
        innerValue(value) {
            this.$emit('input', value)
        },
    },
    mounted() {
        if (this.value) {
            this.innerValue = this.value
        }
    },
}
</script>
